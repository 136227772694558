import toast from 'react-hot-toast';
import { server } from '../../index';
import axios from 'axios';

export const signup = (name, email, phone, password) => async dispatch => {
  try {
    dispatch({ type: 'signupRequest' });

    const { data } = await axios.post(
      `${server}/user/signup`,
      { name, email, phone, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'signupSuccess',
      payload: data.user,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'signupFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

// export const signupWithGoogle = (name, email ) => async dispatch => {
//   try {
//     dispatch({ type: 'signupWithGoogleRequest' }); 

//     const { data } = await axios.post(
//       `${server}/user/signup/google`,
//       { name, email  },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         withCredentials: true,
//       }
//     );

//     dispatch({
//       type: 'signupWithGoogleSuccess',
//       payload: data.user,
//     });
//     toast.success(data.message);
//   } catch (error) {
//     dispatch({
//       type: 'signupWithGoogleFail',
//       payload: error.message,
//     });
//     toast.error(error.message);
//   }
// };

export const login = (email, password) => async dispatch => {
  try {
    dispatch({ type: 'loginRequest' });

    const { data } = await axios.post(
      `${server}/user/login`,
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'loginSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'loginFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const logout = () => async dispatch => {
  try {
    dispatch({ type: 'logoutRequest' });

    const { data } = await axios.get(
      `${server}/user/logout`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: 'logoutSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'logoutFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const LoadUser = () => async dispatch => {
  try {
    dispatch({ type: 'loadUserRequest' });

    const { data } = await axios.get(
      `${server }/user/me`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      } 
    );

    dispatch({
      type: 'loadUserSuccess',
      payload: data.user,
    });
    // toast.success(data.message); 
  } catch (error) {
    dispatch({
      type: 'loadUserFail',
      payload: error.message,
    });
    // toast.error(error.message); 
  }
}; 

export const UserForgetPassword = email => async dispatch => {
  try {
    dispatch({ type: 'forgetPasswordRequest' });

    const { data } = await axios.post(
      `${server}/user/forgetpassword`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'forgetPasswordSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'forgetPasswordFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const UserResetPassword = (password, token) => async dispatch => {
  try {
    dispatch({ type: 'resetPasswordRequest' });

    const { data } = await axios.put(
      `${server}/user/resetpassword/${token}`,
      { password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'resetPasswordSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'resetPasswordFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const Contact = (name, email, phone, message) => async dispatch => {
  try {
    dispatch({ type: 'contactRequest' });

    const { data } = await axios.post(
      `${server}/user/contact`,
      { name, email, phone, message },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'contactSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'contactFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const ContactWithEmail = email => async dispatch => {
  try {
    dispatch({ type: 'contactWithEmailRequest' });

    const { data } = await axios.post(
      `${server}/user/contact/email`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'contactWithEmailSuccess',
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: 'contactWithEmailFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const AdminLoadAllUser = () => async dispatch => {
  try {
    dispatch({ type: 'AdminLoadUserRequest' });

    const { data } = await axios.get(
      `${server}/user/admin/get/all/user`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminLoadUserSuccess',
      payload: data.user,
    });
    // toast.success(data.message); 
  } catch (error) {
    dispatch({
      type: 'AdminLoadUserFail',
      payload: error.message,
    });
    // toast.error(error.message);
  }
};

export const AdminDeleteUser = (id) => async dispatch => {
  try {
    dispatch({ type: 'AdminDeleteUserRequest' });

    const { data } = await axios.delete(
      `${server }/user/admin/delete/user/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminDeleteUserSuccess',
      payload: data
    });
    toast.success(data.message); 
  } catch (error) {
    dispatch({
      type: 'AdminDeleteUserFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};

export const AdminChangeUserRole = (id) => async dispatch => {
  try {
    dispatch({ type: 'AdminChangeUserRoleRequest' });

    const { data } = await axios.get(
      `${server }/user/admin/update/user/role/${id}`,
      
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminChangeUserRoleSuccess',
      payload: data
    });
    toast.success(data.message); 
  } catch (error) {
    dispatch({
      type: 'AdminChangeUserRoleFail',
      payload: error.message,
    });
    toast.error(error.message);
  }
};
