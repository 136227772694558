import {
  Box,
  Grid,
  Heading,
  Stack,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
// import SideBar from '../SideBar/SideBar'
import {
  RiAdminFill,
  RiAdminLine,
  RiDashboardFill,
  RiEyeFill,
  RiUser3Fill,
} from 'react-icons/ri';
import DaskboardDataShow from './DaskboardDataShow';
// import CreateProducts from '../CreateProducts/CreateProducts'
import User from '../User/User';
import { useDispatch, useSelector } from 'react-redux';
import AdminInvoice from '../AdminInvoice/AdminInvoice';
import { AdminLoadAllUser } from '../../../redux/actions/UserActions';
import AdminBills from '../AdminBills/AdminBills';
import { AdminGetAllInvoiceData, GetAllInvoice } from '../../../redux/actions/InvoiceActions';
// import { AdminGetAllOrders } from '../../../Redux/Action/OrderActions'

const Daskboard = () => {
  const dispatch = useDispatch();

  const loadAdminOrder = async () => {
    //  await dispatch(AdminGetAllOrders())
  };
  useEffect(()=>{
    // dispatch(GetAllInvoice())
    // dispatch(AdminLoadAllUser())
    // dispatch(AdminGetAllInvoiceData())
     
  
  },[])

  return (
    <>
     <Heading
        textAlign={'center'}
        children={'Admin Panel'}
        fontSize={'5vmax'}
        color={'#8159d2'}
        as={'h2'}
        fontFamily={'Roboto'}
        letterSpacing={'5px'}
        data-aos="fade-up"
        data-aos-duration="1000"
        bgGradient="linear(to-l,#742cd0, #fd43a4,#fbb44d )"
        bgClip="text"
        m={'2vmax 0'}
      />
      <Tabs m={'1vmax 0'} w={'100%'} align="center" variant="enclosed" isLazy>
        <TabList>
          <Tab
            // onClick={() => loadAdminOrder()}
            fontSize={{ base: '1.1vmax', md: '1.5vmax', lg: '1.3vmax' }}
            _selected={{ color: 'white', bgColor:'purple.500'}}
          >
            <RiDashboardFill />
            New
          </Tab>
          <Tab
            fontSize={{ base: '1.1vmax', md: '1.5vmax', lg: '1.3vmax' }}
            _selected={{ color: 'white', bgColor:'purple.500' }}
          >
            <RiAdminFill />
            My Bills
          </Tab>
          <Tab
            fontSize={{ base: '1.1vmax', md: '1.5vmax', lg: '1.3vmax' }}
            _selected={{ color: 'white', bgColor:'purple.500' }}
          >
            <RiEyeFill />
            Invoice
          </Tab>
          <Tab
            // onClick={() => AdminLoadAllUser()}
            fontSize={{ base: '1.1vmax', md: '1.5vmax', lg: '1.3vmax' }}
            _selected={{ color: 'white', bgColor:'purple.500' }}
          >
            <RiUser3Fill />
            User
          </Tab>
        </TabList>
        {/* <TabIndicator
        height="2px"
        bg="#bfa888"
        borderRadius="1px"
    /> */}
        <TabPanels>
          <TabPanel>
            {/* <DaskboardDataShow /> */}
            <div>new</div>
          </TabPanel>
          <TabPanel>
            <AdminBills />
          </TabPanel>
          <TabPanel>
            <AdminInvoice />
          </TabPanel>
          <TabPanel>
            <User />           
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Daskboard;
