import React from 'react'
import {
    Box, Button, Grid, HStack, Heading, Image, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure
  } from '@chakra-ui/react'
// import ProductModel from './ProductModel'
import { RiDeleteBin7Fill } from 'react-icons/ri' 
// import SideBar from '../SideBar/SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MdDelete } from 'react-icons/md';
import { AdminDeleteInvoice, AdminGetAllInvoiceData, DeleteInvoice, GetAllInvoice } from '../../../redux/actions/InvoiceActions'


const AdminInvoice = () => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const { bills } = useSelector(
    state => state.invoiceContainer.invoice
  );
  const { adminInvoice} = useSelector(
    state => state.invoiceContainer
  );

  const deleteBills=async(_id)=>{

    await dispatch(AdminDeleteInvoice(_id))
     dispatch(AdminGetAllInvoiceData())
   
   }
  return (
    <>
        <HStack w={'100%'} >
    <Box w={'100%'} p={['0', '16']} overflowX={'auto'}>
      <Heading children='All Invoice' textAlign={['center', 'left']} textTransform={'uppercase'} />
      {/* <TableContainer w={['100vw', 'full']} >
        <Table variant={'simple'} size={'lg'}>
          <TableCaption>Aavaiable Course in the Data Base</TableCaption>   
          <Thead>
            <Tr>
              <Th>Id</Th> 
              <Th>Poster</Th>
              <Th>Name</Th>
              <Th>Category</Th>
              <Th isNumeric>Price</Th>
              <Th isNumeric>Stock</Th>
              <Th isNumeric>Action</Th> 
            </Tr>
          </Thead>
          <Tbody>
         
             <Td>Id</Td>
              <Td>Poster</Td>
              <Td>Name</Td>
              <Td>Category</Td>
              <Td isNumeric>Price</Td>
              <Td isNumeric>Stock</Td>
              <Td isNumeric>Action</Td> 
          </Tbody>
        </Table>
      </TableContainer> */}
      {
      adminInvoice && adminInvoice.length>=1?(
        <TableContainer w={'100%'}  fontFamily={'Roboto'} fontSize={['xs','sm','md','lg']}  m={'5.5vmax 0'}>
            <Table  variant="simple">
                <TableCaption>Aavaiable Invoice in the Data Base</TableCaption>
                <Thead  bgGradient='linear(to-l, #7928CA, #FF0080)'>
                    <Tr >
                        <Th color={'white'}>SN#</Th>
                        <Th color={'white'} >Invoice No</Th>
                        <Th color={'white'} >Client Name</Th>
                        <Th color={'white'} >User Name</Th>
                        <Th color={'white'} >Items</Th>
                        <Th color={'white'} >Date</Th> 
                        <Th color={'white'} >Amount</Th>
                        <Th color={'white'} isNumeric>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                  {
                   adminInvoice && adminInvoice.map((item,i)=>(
                      <Tr key={i} >
                        <Td>{i+1}</Td>
                        <Td >{item.invoiceNumber}</Td>
                        <Td>{item.ClientCompanyName}</Td>
                        <Td>{item.user?.name}</Td>
                        <Td>{item.invoiceItem.map((item)=>(
                          <p>{item}</p>
                        ))}</Td>
                        <Td  >{item.invoiceDate}</Td>
                        <Td >
                          {item.totalAmountWithTax}
                        </Td>
                        <Td isNumeric>
                          <Button ml={'1.5vmax'} size={'sm'} onClick={()=>deleteBills(item._id)} colorScheme={'purple'} children={<MdDelete />} />
                        </Td>
                      </Tr>
                    ))
                  }

                </Tbody>
            </Table>
        </TableContainer>
      ):(
        <HStack m={'2vmax 0'} justifyContent={'center'} alignItems={'center'} w={"100%"} >         
          <Text textAlign={'center'} children={'Not Generate Yet'} />
          {/* <Button _hover={{bgGradient:'linear(to-l,#FF0080, #7928CA)'}} bgGradient='linear(to-l, #7928CA, #FF0080)' color={'white'}  m={'0 10px'} fontSize={['sm','sm']} variant={'solid'}  onClick={()=>navigate('/login')} children={'Login'} /> */}
        </HStack>

      )
    }
    </Box>
  </HStack>
    </>
  )
}

export default AdminInvoice