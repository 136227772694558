import React from 'react'
import './Loader.css'

const Loader = () => {
  return (
  <>
  <span className="loader"></span> 
  </>
  )
}

export default Loader