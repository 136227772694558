import React, { useState } from 'react'
import './InvoiceTemplate5.css'
import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Button } from '@chakra-ui/react';

const InvoiceTemplate5 = ({
    BillData,totalAmountWithTax,totalAmount,totalSgstAmount,totalCgstAmount,invoiceItem,invoiceLogo,
    invoiceName,invoiceNumber,invoiceDate,invoiceDueDate,
    CompanyName,YourName,CompanyGSTTin,CompanyCity,CompanyState,CompanyCountry,
    ClientCompanyName,ClientName,ClientGSTTin,ClientCity,ClientState,ClientCountry
}) => {
  const [hidePrintBtn,setHidePrintBtn]=useState(false)

    const dispatch=useDispatch()
    const navigate=useNavigate()

    const InvoiceDataHandler=async()=>{ 
    setHidePrintBtn(true)  

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }
  return (

    
    <>
    <div className="morganTemp">
        <img src={invoiceLogo} alt={'invoiceLogo'} />
        <h3>{`${CompanyName} `}</h3>
        <small>{`GST-Tin${CompanyGSTTin} ${CompanyCity} ${CompanyState} ${CompanyCountry}`}</small>
    </div>
    <div className="issuedSection">
        <div className="issue-to">
            <h3>issue to: {ClientCompanyName}</h3>
            <li>{ClientName}</li>
            <li>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
        </div>

        <div className="issueNum">
            <p>
                <li style={{ fontWeight: "700" }}>invoice no:</li>
                <li>date:</li>
                <li>due date:</li>
            </p>
            <p>
                <li style={{ fontWeight: "700" }}>{invoiceNumber}</li>
                <li>{invoiceDate}</li>
                <li>{invoiceDueDate}</li>
            </p>
        </div>
    </div>


    <div className="morganTable mt-5">
        <Table responsive="md">
            <thead>

                <tr >
                    <th style={{ backgroundColor: "#DCCEAC" }}>DESCRIPTION</th>
                    <th style={{ backgroundColor: "#DCCEAC" }}> QTY</th>
                    <th style={{ backgroundColor: "#DCCEAC"}}>PRICE</th>
                    <th style={{ backgroundColor: "#DCCEAC",display:"flex",alignItems:"end",justifyContent:"flex-end"}}>TOTAL</th>
                </tr>


            </thead>
            <tbody>
                {

                    BillData && BillData.map((Item, key) => (
                        <tr key={key} >
                            <td style={{ fontWeight: "400" }}>{Item.itemDes}</td>
                            <td style={{ fontWeight: "400" }}>{Item.qty}</td>
                            <td style={{ fontWeight: "400" }}>{Item.rate}</td>
                            <td style={{ fontWeight: "400",display:"flex",alignItems:"end",justifyContent:"flex-end"}}>{Item.amount}</td>

                        </tr>
                    ))
                }
            </tbody>
        </Table>
    </div>

    <div className="tableSubtotal">
        <div className="morgab-sub">
            subtotal
        </div>
        <div className="morganAmount">
            <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>{totalAmount}</p>
            <div className="mor-price">
                <p style={{ marginRight: "3.5rem" }}>tax</p>
                <p>{totalSgstAmount+totalCgstAmount}</p>
            </div>


        </div>
    </div>

    <div className="greyTotal">
        <p style={{ marginRight: "2rem" }}>total</p>
        <p>{totalAmountWithTax}</p>
    </div>

    <div className="lastfoot-morgan mt-5">
        <div className="morganDetails">
            <h3>Company signature</h3>
           
        </div>

        <div className="morganSign">
            <h3>Customer signure</h3>
        </div>
    </div>
    {
        hidePrintBtn?null:<Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={()=>InvoiceDataHandler()} />
      }

</>
  )
}

export default InvoiceTemplate5