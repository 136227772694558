import {
    Box, Button, Grid, HStack, Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiDeleteBin7Fill } from 'react-icons/ri'
// import SideBar from '../SideBar/SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { AdminChangeUserRole, AdminDeleteUser, AdminLoadAllUser } from '../../../redux/actions/UserActions'

const User = () => {

    // const allUser=[
    //     {
    //                 _id: 'jdfld',
    //                 name: 'shamim',
    //                 email: 'dfkdjfodf',
    //                 role: 'user',
    //                 subscription: {
    //                     status: 'active',
    //                 },
    //                 action: ''
    //     }
    // ]
	const dispatch = useDispatch();
    const { allUser, loading, isAuthenticated } = useSelector(
		state => state.userContainer
	);
    // const user = [
    //     {
    //         _id: 'jdfld',
    //         name: 'shamim',
    //         email: 'dfkdjfodf',
    //         role: 'user',
    //         subscription: {
    //             status: 'active',
    //         },
    //         action: ''
    //     }
    // ]
    const deleteHandler =async (id) => {
        await dispatch(AdminDeleteUser(id))
        dispatch(AdminLoadAllUser())
        
    }
    const updateRoleHandler =async (id) => {
        // const role='admin';
        await dispatch(AdminChangeUserRole(id))
        dispatch(AdminLoadAllUser())

    }

    // useEffect(()=>{
    //     dispatch(AdminLoadAllUser())
    // },[])
  return (
    <HStack w={'100%'} >
      

    <Box w={'100%'} p={['0', '16']} overflowX={'auto'}>
        <Heading children='all User ' textAlign={['center', 'left']} textTransform={'uppercase'} />
        <TableContainer w={'100%'} fontFamily={'Roboto'} fontSize={['xs','sm','md','lg']}  m={'5.5vmax 0'}>
            <Table variant={'simple'}  >
                <TableCaption>Aavaiable User in the Data Base</TableCaption>
                <Thead bgGradient='linear(to-l, #7928CA, #FF0080)'>
                    <Tr>
                        <Th color={'white'}>Id</Th>
                        <Th color={'white'}>Name</Th>
                        <Th color={'white'}>Email</Th>
                        <Th color={'white'}>Role</Th>
                        {/* <Th>Subscription</Th> */}
                        <Th color={'white'} isNumeric>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        allUser && allUser.map((item, i) => (
                            <Tr key={i}>
                                <Td>{item._id}</Td>
                                <Td>{item.name}</Td>
                                <Td>{item.email}</Td>
                                <Td>{item.role}</Td>
                                {/* <Td>
                                    #{item.subscription.status === 'active' ? 'Active' : 'Not Active'}
                                    active
                                </Td> */}
                                <Td isNumeric>
                                    <HStack justifyContent={'flex-end'}>
                                        <Button variant={'outline'} color={'purple.400'} onClick={() => updateRoleHandler(item._id)}>
                                            Change Role
                                        </Button>
                                        <Button color={'purple.600'} onClick={() => deleteHandler(item._id)} >
                                            <RiDeleteBin7Fill />
                                        </Button>
                                    </HStack>
                                </Td>

                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </TableContainer>
    </Box>
</HStack>
  )
}

export default User