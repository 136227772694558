import React, { useState } from 'react'
import './InvoiceTemplate8.css'
import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Button } from '@chakra-ui/react';


const InvoiceTemplate8 = ({
    BillData,totalAmountWithTax,totalAmount,totalSgstAmount,totalCgstAmount,invoiceItem,invoiceLogo,
    invoiceName,invoiceNumber,invoiceDate,invoiceDueDate,
    CompanyName,YourName,CompanyGSTTin,CompanyCity,CompanyState,CompanyCountry,
    ClientCompanyName,ClientName,ClientGSTTin,ClientCity,ClientState,ClientCountry
}) => {
  const [hidePrintBtn,setHidePrintBtn]=useState(false)

    const dispatch=useDispatch()
    const navigate=useNavigate()
  
    const InvoiceDataHandler=async()=>{ 
    setHidePrintBtn(true)  

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }

    return (
        <>
            <div className="gigSection">
                <div className="gigYellowClr">
                    <div className="bgyellow"></div>
                    <div className="gigCaption">
                        {/* <img src={invoiceLogo} alt="invoiceLogo" /> */}
                        <h3>{CompanyName}</h3>
                        <h3>{YourName}</h3>
                        <li>{`GST-Tin${CompanyGSTTin} ${CompanyCity} ${CompanyState} ${CompanyCountry}`}</li>
                        <li>123 456 789</li>
                    </div>
                </div>


                <div className="yellowInvoice">
                    invoice
                </div>
            </div>

            <div className="billTo">
                <div className="billyellow">
                    <h3>bill to:</h3>
                    <li>{ClientName}</li>
                    <li>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
                    <li>123 456 789</li>
                </div>
                <div className="yellowinvoicno">
                    <div className="yellowList1">
                        <li>Invoice number:</li>
                        <li>Date:</li>
                        <li>Due date:</li>
                    </div>
                    <div className="yellowlist2">
                        <li>{invoiceNumber}</li>
                        <li>{invoiceDate}</li>
                        <li>{invoiceDueDate}</li>
                    </div>
                </div>
            </div>

            <div className="bgyellowTable mt-5">
                <Table responsive="md">
                    <thead>
                        <tr >
                            <th style={{ borderBottom: "3px solid black" }}>DESCRIPTION</th>
                            <th style={{ borderBottom: "3px solid black" }}> QTY</th>
                            <th style={{ borderBottom: "3px solid black" }}>PRICE</th>
                            <th style={{ borderBottom: "3px solid black" }}>TOTAL</th>
                        </tr>

                    </thead>
                    <tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <tr key={key} >
                                    <td style={{ fontWeight: "400", borderBottom: "3px solid black", padding: "1.3rem" }}>{Item.itemDes}</td>
                                    <td style={{ fontWeight: "400", borderBottom: "3px solid black", padding: "1.3rem" }}>{Item.qty}</td>
                                    <td style={{ fontWeight: "400", borderBottom: "3px solid black", padding: "1.3rem" }}>{Item.rate}</td>
                                    <td style={{ fontWeight: "400", borderBottom: "3px solid black", padding: "1.3rem" }}>{Item.amount}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>


            <div className="yellowTotal">
                <div className="yellowSub">
                    <li>Sub Total</li>
                    <li>Tax</li>
                    {/* <li>discount</li> */}
                </div>

                <div className="yelloamount" >
                    <li >{totalAmount}</li>
                    <li >{totalSgstAmount+totalCgstAmount}</li>
                    {/* <li>-</li> */}
                </div>
            </div>
            <div className="yellowsubTotal">
                <p>Total</p>
                <p>{totalAmountWithTax}</p>
            </div>

            <div className="paymentMethod mt-3">
                <p>
                    <h3>payment method</h3>
                    <li>central bank</li>
                    <li>samira hadid</li>
                    <li>123-456-789</li>
                </p>

                <p style={{textAlign:'center'}}>
                    <h5>{YourName}</h5>
                    <small>manager</small>
                </p>
            </div>
            {
        hidePrintBtn?null:<Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={()=>InvoiceDataHandler()} />
      }

        </>
    )
}

export default InvoiceTemplate8