import React, { useEffect } from 'react'
import {
    Box, Button, Grid, HStack, Heading, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { DeleteInvoice, GetAllInvoice } from '../../../redux/actions/InvoiceActions';


const AdminBills = () => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const { bills } = useSelector(
        state => state.invoiceContainer.invoice
      );
      const deleteBills=async(_id)=>{

        await dispatch(DeleteInvoice(_id))
         dispatch(GetAllInvoice())
       
       }
       useEffect(()=>{
        dispatch(GetAllInvoice())
      },[])
  return (
    <>
    <HStack w={'100%'} >
      <Box w={'100%'} p={['0', '16']} overflowX={'auto'}>
          <Heading children='All Bills ' textAlign={['center', 'left']} textTransform={'uppercase'} />
         {
      bills && bills.length>=1?(
        <TableContainer w={'100%'}  fontFamily={'Roboto'} fontSize={['xs','sm','md','lg']}  m={'5.5vmax 0'}>
            <Table  variant="simple">
                <Thead  bgGradient='linear(to-l, #7928CA, #FF0080)'>
                    <Tr >
                        <Th color={'white'}>SN#</Th>
                        <Th color={'white'} >Invoice No</Th>
                        <Th color={'white'} >Client Name</Th>
                        <Th color={'white'} >Items</Th>
                        <Th color={'white'} >Date</Th> 
                        <Th color={'white'} >Amount</Th>
                        <Th color={'white'} isNumeric>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                  {
                   bills && bills.map((item,i)=>(
                      <Tr key={i} >
                        <Td>{i+1}</Td>
                        <Td >{item.invoiceNumber}</Td>
                        <Td>{item.ClientCompanyName}</Td>
                        <Td>{item.invoiceItem.map((item)=>(
                          <p>{item}</p>
                        ))}</Td>
                        <Td  >{item.invoiceDate}</Td>
                        <Td >
                          {item.totalAmountWithTax}
                        </Td>
                        <Td isNumeric>
                          <Button ml={'1.5vmax'} size={'sm'} onClick={()=>deleteBills(item._id)} colorScheme={'purple'} children={<MdDelete />} />
                        </Td>
                      </Tr>
                    ))
                  }

                </Tbody>
            </Table>
        </TableContainer>
      ):(
        <HStack m={'2vmax 0'} justifyContent={'center'} alignItems={'center'} w={"100%"} >         
          <Text textAlign={'center'} children={'Not Generate Yet'} />
        </HStack>
      )
    }
      </Box>
  </HStack>
    </>
  )
}

export default AdminBills