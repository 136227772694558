import { ChakraProvider, ColorModeScript, theme } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Provider } from 'react-redux';
import Store from './redux/Store';
// import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
export const server = 'https://invoice-server-sepia.vercel.app/api'; 
// export const server = 'http://localhost:5000/api';

root.render(
  <StrictMode>
    {/* <GoogleOAuthProvider clientId="938490479890-e7l5djt6lbfjojs9kgkln343mocve7d7.apps.googleusercontent.com">   */}
      <Provider store={Store}>
        <ChakraProvider theme={theme}>
          {/* <ColorModeScript /> */}
          {/* <ColorModeSwitcher /> */}
          <App />
        </ChakraProvider>
      </Provider>
    {/* </GoogleOAuthProvider>; */}
  </StrictMode>
);
