import React, { useState } from 'react'
import './InvoiceTemplate4.css'
import logo2 from '../../../img/logo.jpeg';
import Table from 'react-bootstrap/Table';
import { Button } from '@chakra-ui/react';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const InvoiceTemplate4 = ({
    BillData,totalAmountWithTax,totalAmount,totalSgstAmount,totalCgstAmount,invoiceItem,invoiceLogo,
    invoiceName,invoiceNumber,invoiceDate,invoiceDueDate,
    CompanyName,YourName,CompanyGSTTin,CompanyCity,CompanyState,CompanyCountry,
    ClientCompanyName,ClientName,ClientGSTTin,ClientCity,ClientState,ClientCountry
}) => {
  const [hidePrintBtn,setHidePrintBtn]=useState(false)


    const dispatch=useDispatch()
    const navigate=useNavigate()

    const InvoiceDataHandler=async()=>{  
    setHidePrintBtn(true)  

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }

  return (
    <>
    <header>
        <div className="left-head">
            <p><img style={{width:"100%"}} src={invoiceLogo} alt="invoiceLogo" />{CompanyName}</p>
           
        </div>
        <div className="right-head">
            INVOICE
        </div>
    </header>
    <div id='invoceTo'>
        <li>invoice to:{ClientCompanyName}</li>
        <li style={{ fontWeight: "800", textTransform: "uppercase", fontSize: "1.2rem" }}>{ClientName}</li>
    </div>

    <section>
        <div className="section-left">
            <li>P : 123-456-789</li>
            <li>A : {`${ClientCity} ${ClientState} ${ClientCountry}`}</li>
        </div>

        <div className="section-right">
            <li>invoice date: </li>
            <li style={{ fontWeight: "800" }}>{invoiceDate}</li>
        </div>
    </section>

    <div className="table-list mt-3">
        <Table responsive="md">
            <thead>
                {/* <div className="tr-bg"> */}
                <tr id='tr-bg'  >
                    <th>DESCRIPTION</th>
                    <th > QTY</th>
                    <th >TOTAL</th>
                </tr>
                {/* </div> */}

            </thead>
            <tbody>
                {

                    BillData && BillData.map((Item, key) => (
                        <tr key={key} >
                            <td style={{ color: "rgb(6, 6, 83)", fontWeight: "700"}}>{Item.itemDes}</td>
                            <td style={{ color: "rgb(6, 6, 83)", fontWeight: "700" }}>{Item.qty}</td>
                            <td style={{ color: "rgb(6, 6, 83)", fontWeight: "700" }}>{Item.amount}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    </div>

    <div id="invoiceTerm">
        <div id="term-left">
            <h3>terms & conditions</h3>
            <p>Lorem ipsum dolor sit, amet Lorem ipsum dolor sit amet.consectetur adipisicing elit. Nihil, praesentium.</p>
        </div>

        <div id="term-right">
            <div id="amount-cal">
                <div id="subAmount">
                    <p>sub-total: </p>
                    <p>tax: </p>
                    <p style={{ backgroundColor: "#2b5464", color: "white", padding: "5px", borderRadius: "5px 0 0 5px" }}>total:</p>
                </div>

                <div id="totalAmount">
                    <p>{totalAmount}</p>
                    <p>{totalSgstAmount+totalCgstAmount}</p>
                    <p style={{ backgroundColor: "#2b5464", color: "white", marginLeft: "0", padding: "5px", borderRadius: "0px 5px 5px 0px" }}>{totalAmountWithTax}</p>
                </div>

            </div> 
        </div>
    </div>

    <div className="bottom-head">
        <div className="bottom-left">
           
                <li>Phone number:</li>
                <li>123-456-789</li>
                <li>Email address:</li>
                <li>hello@gmail.com</li>
            
        </div>

        <div className="bottom-right">
            <h3>{YourName}</h3>
            <div className="underline2"></div>
            <small>administrator</small>
        </div>
    </div>
     {
        hidePrintBtn?null:<Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={()=>InvoiceDataHandler()} />
      }

</>
  )
}

export default InvoiceTemplate4