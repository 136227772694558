import React, { useState } from 'react'
import './InvoiceTemplate6.css'
import Table from 'react-bootstrap/Table';
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaChrome } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';

const InvoiceTemplate6 = ({
    BillData,totalAmountWithTax,totalAmount,totalSgstAmount,totalCgstAmount,invoiceItem,invoiceLogo,
    invoiceName,invoiceNumber,invoiceDate,invoiceDueDate,
    CompanyName,YourName,CompanyGSTTin,CompanyCity,CompanyState,CompanyCountry,
    ClientCompanyName,ClientName,ClientGSTTin,ClientCity,ClientState,ClientCountry
}) => {
  const [hidePrintBtn,setHidePrintBtn]=useState(false)

    const dispatch=useDispatch()
    const navigate=useNavigate()

    const InvoiceDataHandler=async()=>{    
    setHidePrintBtn(true)  

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }
  return (
    <>
    <div className="topheader">
        <div className="topheaderlogo">
            <img src={invoiceLogo} alt="invoiceLogo" />
            <div className="headName">
                <h3>{`${YourName}`}</h3>
                <span>{`${CompanyName} `}</span>
            </div>
        </div>

        <small>invoice</small>
    </div>

    <div className="payBill-section">
        <div className="payData">
            
                <h3>{ClientCompanyName}</h3>
                <li>{ClientName}</li>
                <li>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
        
                {/* <h3 className='mt-3'>bank details</h3>
                <li>sailford  & co.</li>
                <li>0123 45678912345</li> */}
        
        </div>

        <div className="bgGreenpic">
            <div className="bggreenText">
            <li style={{display:"flex",alignItems:"end",justifyContent:"flex-end"}}>number: {invoiceNumber}</li>
            <li>date: {invoiceDate}</li> 
            </div>
           
        </div>
    </div>
    <div className="greenTable">
        <Table responsive="md">
            <thead>

                <tr >
                    <th style={{ backgroundColor: "#19bbbd", color: "white", borderRadius: "20px 0 0 20px" }}>DESCRIPTION</th>
                    <th style={{ backgroundColor: "#19bbbd", color: "white" }}> QTY</th>
                    <th style={{ backgroundColor: "#19bbbd", color: "white" }}>PRICE</th>
                    <th style={{ backgroundColor: "#19bbbd", color: "white", borderRadius: "0px 20px 20px 0px",display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>TOTAL</th>
                </tr>


            </thead>
            <tbody>
                {

                    BillData && BillData.map((Item, key) => (
                        <tr key={key} >
                            <td style={{ color: " rgb(72, 119, 138)", fontWeight: "400" }}>{Item.itemDes}</td>
                            <td style={{ color: " rgb(72, 119, 138)", fontWeight: "400" }}>{Item.qty}</td>
                            <td style={{ color: " rgb(72, 119, 138)", fontWeight: "400" }}>{Item.rate}</td>
                            <td style={{ color: " rgb(72, 119, 138)", fontWeight: "400",display:"flex",alignItems:"flex-end",justifyContent:"flex-end" }}>{Item.amount}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    </div>

    <div className="notes">
        <div className="notesOne">
            <h3>notes</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, vitae quisquam fugit laudantium, corporis saepe nam facere libero quaerat dolore, culpa pariatur perferendis sunt odit.</p>
        </div>

        <div className="notesTwo">
            <p>sub total</p>
            <p>tax</p>
            <p>grand total</p>
        </div>

        <div className="notesThree">
            <p>{totalAmount}</p>
            <p>{totalSgstAmount+totalCgstAmount}</p>
            <p>{totalAmountWithTax}</p>
        </div>
    </div>

    <div className="temp-foot-clr">
        <div className="TEMP-FOOT">
            <div className="foot1">
                <p> QuickBill.ai</p>
            </div>

            <div className="foot2">
                <p> 123-456-789</p>
            </div>

            <div className="foot3">
                <p>QuickBill.ai@gmail.com</p>
            </div>
        </div>

    </div>
    {
        hidePrintBtn?null:<Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={()=>InvoiceDataHandler()} />
      }

</>
  )
}

export default InvoiceTemplate6